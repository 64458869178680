import { render, staticRenderFns } from "./JeepayTextUp.vue?vue&type=template&id=90a587e2&scoped=true&"
import script from "./JeepayTextUp.vue?vue&type=script&lang=js&"
export * from "./JeepayTextUp.vue?vue&type=script&lang=js&"
import style0 from "./JeepayTextUp.vue?vue&type=style&index=0&id=90a587e2&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "90a587e2",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/var/lib/jenkins/workspace/YGPay-Web-Merchant/jeepay-ui-merchant/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('90a587e2')) {
      api.createRecord('90a587e2', component.options)
    } else {
      api.reload('90a587e2', component.options)
    }
    module.hot.accept("./JeepayTextUp.vue?vue&type=template&id=90a587e2&scoped=true&", function () {
      api.rerender('90a587e2', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/JeepayTextUp/JeepayTextUp.vue"
export default component.exports